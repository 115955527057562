<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack" :disabled="loading">뒤로</Button>
      <Button @click="onSubmit" type="primary" :disabled="loading">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="키워드">
          <Input placeholder="키워드" size="large" v-model="form.keyword"></Input>
        </FormItem>
        <FormItem label="제목">
          <Input placeholder="제목" size="large" v-model="form.title"></Input>
        </FormItem>
        <FormItem label="가격">
          <Input placeholder="숫자만 입력" size="large" v-model="form.price"></Input>
        </FormItem>
        <FormItem label="할인금액">
          <Input placeholder="할인 할 가격" size="large" v-model="form.discount_price"></Input>
        </FormItem>
        <FormItem label="기간(개월 수)">
          <Input size="large" v-model="form.duration"></Input>
        </FormItem>
        <FormItem label="클래스">
          <Select v-model="form.lectures" multiple>
            <Option v-for="(lecture, index) in meta.lectures" :key="`option-${index}`" :value="lecture.id">
              {{lecture.title}}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="메인 패키지">
          <Checkbox size="large" v-model="form.is_main">해당 패키지은 메인 패키지입니다.</Checkbox>
        </FormItem>
        <FormItem label="공개 설정">
          <Checkbox size="large" v-model="form.is_open">해당 패키지을 공개합니다.</Checkbox>
        </FormItem>
        <FormItem label="공개 기간">
          <Row>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="공개 시작일"
                           type="datetime"
                           v-model="form.start_at"
              ></CDatePicker>
            </Col>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="공개 종료일"
                           type="datetime"
                           v-model="form.end_at"
              ></CDatePicker>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      if (this.isModify) {
        return `product/${this.id}`
      } else {
        return `product`
      }
    }
  },
  data() {
    return {
      form: {
        keyword: '',
        title: '',
        duration: 24,
        is_open: false,
        is_main: false,
        start_at: null,
        end_at: null,
        price: null,
        discount_price: 0,
        lectures: [],
      },
      meta: {
        lectures: [],
      }
    }
  },
  methods: {
    getLectures() {
      this.$axios.$get('lecture/simple').then(res => {
        this.meta.lectures = res.result
      })
    }
  },
  mounted() {
    if (this.isModify) {
      this.getData()
    } else {
      this.getLectures()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>